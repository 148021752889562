










































import {Component, Mixins, Prop} from 'vue-property-decorator';
import {
    BDropdown,
    BDropdownItem,
    BIconThreeDotsVertical,
    BDropdownHeader,
    BIconXCircle,
    BIconPlusCircle,
    BIconGearWide,
    BIconLightning,
    BDropdownItemButton,
    BModal,
    BDropdownText,
    VBModal
} from 'bootstrap-vue';

import {Organization} from '@stellarbeat/js-stellar-domain';
import Store from '@/store/Store';
import AddOrganizationsTable from '@/components/node/tools/simulation/add-organizations-table.vue';
import {StoreMixin} from '@/mixins/StoreMixin';

@Component({
    components: {
        AddOrganizationsTable,
        BDropdown,
        BDropdownText,
        BDropdownItem,
        BIconThreeDotsVertical,
        BDropdownHeader,
        BIconXCircle,
        BIconGearWide,
        BIconLightning,
        BDropdownItemButton,
        BIconPlusCircle,
        BModal
    },
    directives: {'b-modal': VBModal}
})
export default class OrganizationActions extends Mixins(StoreMixin) {
    @Prop()
    public organization!: Organization;
    @Prop({default: false})
    public supportsDelete!: Boolean;
    @Prop({default: false})
    public supportsAdd!: Boolean;
    @Prop({default: true})
    public supportsHalt!: Boolean;

    public organizationsToAdd: Organization[] = [];
    public id!: number;

    get trustedOrganizationIds() {
        let trustedOrganizationIds = new Set<string>();
        this.organization.validators.forEach(publicKey => {
            let validator = this.network.getNodeByPublicKey(publicKey)!;
            this.network.getTrustedOrganizations(validator.quorumSet).forEach(org => {
                if (org.id !== this.organization.id)
                    trustedOrganizationIds.add(org.id);
            });
        });
        return Array.from(trustedOrganizationIds);
    }

    public get possibleOrganizationsToAdd() {
        return this.store.network.organizations
            .filter((organization) => this.trustedOrganizationIds.indexOf(organization.id) < 0);
    }

    organizationsToAddModalOk(bvEvent: any, modalId: string) {
        if (this.organizationsToAdd.length > 0) {
            this.store.addOrganizationsToOrganization(this.organizationsToAdd, this.organization);
        }
    }

    onOrganizationsSelected(organizations: Organization[]) {
        this.organizationsToAdd = organizations;
    }

    created() {
        this.id = this.store.getUniqueId();
    }
}
