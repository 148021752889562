




import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component({
    name: 'github'
})
export default class Github extends Vue {
}
