




















































import moment from 'moment';
import {Component, Mixins, Prop} from 'vue-property-decorator';
import {BTooltip, BIconInfoCircle, VBTooltip, BButton, BModal, BBadge} from 'bootstrap-vue';
import NetworkStatisticsChart from '@/components/network/cards/network-statistics/network-statistics-chart.vue';
import NetworkStatisticsAggregation from '@stellarbeat/js-stellar-domain/lib/network-statistics-aggregation';
import {StoreMixin} from '@/mixins/StoreMixin';

@Component({
    components: {NetworkStatisticsChart, BTooltip, BIconInfoCircle, BButton, BModal, BBadge},
    directives: {'b-tooltip': VBTooltip}
})
export default class NetworkStatisticsCard extends Mixins(StoreMixin) {
    @Prop({default: true})
    isLoading!: boolean;
    @Prop()
    tooltip!: string;
    @Prop()
    title!: string;
    @Prop()
    value!: number;
    @Prop()
    initialDataLoaded!: boolean;
    @Prop()
    yearStatistics!: NetworkStatisticsAggregation[];
    @Prop()
    statsProperty!: string;
    @Prop({default: false})
    isBool!: boolean;
    @Prop({default: false})
    isSimulationSensitive!: boolean;

    activeElement: NetworkStatisticsAggregation | null = null;
    showModal: boolean = false;

    get hasActiveElement() {
        return this.activeElement !== null;
    }

    onHover(stat: NetworkStatisticsAggregation) {
        this.activeElement = stat;
    }

    formatTime(date: Date) {
        return moment(date).format('MMM YYYY');
    }

    get dimmerClass() {
        return {
            dimmer: true,
            active: this.isLoading,
        };
    }
}
