














































































import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {
    NetworkGraphNode,
    QuorumSet as NetworkQuorumSet
} from '@stellar/halting-analysis/src';
import {Network, PublicKey, QuorumSet, Vertex} from '@stellarbeat/js-stellar-domain';
import HaltingAnalysisWorker
    from 'worker-loader?name=worker/[name].js!./../../../../workers/halting-analysisv1.worker.ts';
import Store from '@/store/Store';
import {BAlert, BButton, BCard, BForm, BFormGroup, BFormInput, BFormSelect, BIconX} from 'bootstrap-vue';
import {AggregateChange} from '@/services/change-queue/changes/aggregate-change';
import {EntityPropertyUpdate} from '@/services/change-queue/changes/entity-property-update';

const _HaltingAnalysisWorker: any = HaltingAnalysisWorker; // workaround for typescript not compiling web workers.

@Component({
    name: 'halting-analysis',
    components: {
        BCard: BCard,
        BForm: BForm,
        BButton: BButton,
        BFormSelect: BFormSelect,
        BAlert: BAlert,
        BFormInput: BFormInput,
        BFormGroup: BFormGroup,
        BIconX: BIconX
    }
})
export default class HaltingAnalysis extends Vue {
    @Prop()
    protected publicKey!: PublicKey;

    protected showAnalysisResult: boolean = false;
    protected numberOfNodeFailures: number = 2;
    protected numberOfNodeFailuresInputState: boolean | null = null;
    protected nodeFailures: { value: string[], text: string }[] = [];
    protected selectedFailure: PublicKey[] | null = null;
    protected isLoading: boolean = false;
    protected simulated: boolean = false;

    protected haltingAnalysisWorker = new _HaltingAnalysisWorker();

    get store(): Store {
        return this.$root.$data.store;
    }

    get network():Network {
        return this.store.network;
    }

    get dimmerClass() {
        return {
            dimmer: true,
            active: this.isLoading,
        };
    }

    @Watch('publicKey')
    public onPublicKeyChanged() {
        this.nodeFailures = [];
        this.selectedFailure = null;
        this.simulated = false;
        this.showAnalysisResult = false;
    }

    get vertex() {
        return this.network.nodesTrustGraph.getVertex(this.publicKey);
    }

    get node(){
        return this.network.getNodeByPublicKey(this.publicKey);
    }

    getNetworkGraphNodes() {
        return Array.from(this.network.nodesTrustGraph.vertices.values()) //todo only nodes in transitive quorum set
            .map(vertex => this.mapVertexToNetworkGraphNode(vertex, vertex === this.vertex));
    }

    simulateFailure() {
        if (this.selectedFailure === null) {
            return;
        }
        this.simulated = true;
        let aggregateChange = new AggregateChange(
            this.selectedFailure.map(failurePublicKey => new EntityPropertyUpdate(this.network.getNodeByPublicKey(failurePublicKey), 'isValidating', false))
        )

        this.store.processChange(aggregateChange);
    }

    resetFailureSimulation() {
        if (this.selectedFailure === null) {
            return;
        }
        let aggregateChange = new AggregateChange(
            this.selectedFailure.map(failurePublicKey => new EntityPropertyUpdate(this.network.getNodeByPublicKey(failurePublicKey), 'isValidating', true))
        )

        this.store.processChange(aggregateChange);
        this.simulated = false;
    }

    restartHaltingAnalysis() {
        this.isLoading = true;
        this.simulated = false;
        this.haltingAnalysisWorker.postMessage({
            networkGraphNodes: this.getNetworkGraphNodes(),
            numberOfNodeFailures: this.numberOfNodeFailures
        });
    }

    mapVertexToNetworkGraphNode(vertex: Vertex, isRoot: boolean) {
        return {
            'distance': isRoot ? 0 : 1,
            'node': vertex.key,
            'status': !this.network.isNodeFailing(this.network.getNodeByPublicKey(vertex.key)) ? 'tracking' : 'missing',
            'qset': !this.network.isNodeFailing(this.network.getNodeByPublicKey(vertex.key)) ? this.mapQuorumSetToNetworkQuorumSet(this.network.getNodeByPublicKey(vertex.key)!.quorumSet) : undefined
        } as NetworkGraphNode;
    }

    mapQuorumSetToNetworkQuorumSet(quorumSet: QuorumSet): NetworkQuorumSet {
        let innerQSets =
            quorumSet.innerQuorumSets.map(innerQSet => this.mapQuorumSetToNetworkQuorumSet(innerQSet));
        let v = [];
        v.push(...quorumSet.validators);
        innerQSets.forEach(innerQSet => v.push(innerQSet));
        return {
            't': quorumSet.threshold,
            'v': v
        };
    }

    mounted() {
        this.haltingAnalysisWorker.onmessage = (
            event: { data: { type: string, failures: any } }
        ) => {
            switch (event.data.type) {
                case 'tick': {

                }
                    break;
                case 'end': {
                    this.nodeFailures = event.data.failures.map((failure: Array<PublicKey>) => {
                        return {
                            value: failure,
                            text: failure.map(publicKey =>
                                this.network.getNodeByPublicKey(publicKey)!.name ?
                                    this.network.getNodeByPublicKey(publicKey)!.displayName :
                                    publicKey.substr(0, 5)
                            ).join(', ')
                        };
                    });
                    if (this.nodeFailures.length > 0) {
                        this.selectedFailure = this.nodeFailures[0].value;
                    }
                    this.showAnalysisResult = true;
                    this.isLoading = false;
                }
                    break;
            }
        };
    }
}
