import { render, staticRenderFns } from "./simulate-new-node.vue?vue&type=template&id=10b14418&scoped=true&"
import script from "./simulate-new-node.vue?vue&type=script&lang=ts&"
export * from "./simulate-new-node.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10b14418",
  null
  
)

export default component.exports