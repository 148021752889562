









































import {Component, Prop, Mixins} from 'vue-property-decorator';
import {Organization} from '@stellarbeat/js-stellar-domain';
import NavLink from '@/components/side-bar/nav-link.vue';
import {DropdownMixin} from '@/components/side-bar/dropdown-mixin';
import NavDropdownLink from '@/components/side-bar/nav-dropdown-link.vue';
import NavPagination from '@/components/side-bar/nav-pagination.vue';
import OrganizationActions from '@/components/organization/sidebar/organization-actions.vue';

@Component({
    components: {
        OrganizationActions,
        NavPagination,
        NavLink
    },
})
export default class OrganizationsDropdown extends Mixins(DropdownMixin) {
    @Prop()
    public organizations!: Organization[];

    get paginatedOrganizations() {
        return this.paginate(this.organizations)
            .sort((orgA: Organization, orgB: Organization) => {
                if (orgA.name > orgB.name)
                    return 1;
                else return -1;
            });
    }

    public selectOrganization(organization: Organization) {
        this.$router.push({
            name: 'organization-dashboard',
            params: {organizationId: organization.id},
            query: {view: this.$route.query.view, 'no-scroll': '0', 'network': this.$route.query.network, 'at': this.$route.query.at}
        });
    }

    public hasWarnings(organization: Organization) {
        return this.store.organizationHasWarnings(organization);
    }

    public getFailAt(organization: Organization) {
        let nrOfValidatingNodes = organization.validators
            .map(validator => this.network.getNodeByPublicKey(validator))
            .filter(validator => validator !== undefined)
            .filter(node => node!.isValidating).length;

        return nrOfValidatingNodes - organization.subQuorumThreshold + 1;
    }


}
